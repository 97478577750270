import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layout'
import PhotosetListing from '../components/PhotosetListing'
import SEO from '../components/SEO'

export default function Photosets() {
  const { allContentfulPhotosets } = useStaticQuery(graphql`
    query Photosets {
      allContentfulPhotosets(sort: { order: DESC, fields: createdAt }) {
        edges {
          node {
            cover {
              localFile {
                childImageSharp {
                  fixed(
                    width: 325
                    height: 250
                    quality: 80
                    traceSVG: { background: "#fbfafc", color: "#c9f7ff" }
                  ) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
            id
            title
            slug
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Photosets" slug="/photosets" />
      <div className="container">
        <h1>Photosets</h1>
        <PhotosetListing photosets={allContentfulPhotosets} />
      </div>
    </Layout>
  )
}
